import React, { useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';

// Set up the modal
Modal.setAppElement('#root');

function FileUploadPage() {
  const [csvFiles, setcsvFiles] = useState([]);
  const [uploadedData, setUploadedData] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [showModal, setShowModal] = useState(false); // State for showing/hiding modal

  const handlecsvFilesChange = (event) => {
    const selectedFile = event.target.files[0];
    const fileType = selectedFile.type;

    if (fileType === 'application/x-msdownload') {
      // It's an .exe file, allow it
      setcsvFiles(event.target.files);
      setSelectedFileName(event.target.files[0].name);
    } else {
      // It's not an .exe file, show an error or take appropriate action
      alert('Only .exe files are allowed.');
      // Optionally, clear the file input field
      event.target.value = null;
    }
  };

  const handleEmailChange = (event) => {
    setEmailAddress(event.target.value);
  };

  const handleUpload = async () => {
    const formData = new FormData();

    for (const file of csvFiles) {
      formData.append('file', file);
    }

    formData.append('email', emailAddress);

    try {
      setIsUploading(true);
      const response = await axios.post(`https://boss-initially-panda.ngrok-free.app/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'ngrok-skip-browser-warning': 'skip'
        },
      });
      setUploadedData(response.data);
      console.log(response.data);
      setShowModal(true); // Show the modal after successful upload
    } catch (error) {
      console.error('Error uploading files:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleBack = () => {
    window.open('https://deeprw.com', '_self');
  };

  return (
    <div className="bg-gray-200 px-32 min-h-screen flex flex-col items-start justify-start py-20">
      <div className="container mx-auto">
        <button
          onClick={handleBack}
          className="border border-white bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full transition duration-300 ease-in-out focus:outline-none focus:ring focus:ring-blue-300"
        >
          Back
        </button>

        <div className="flex items-center justify-center gap-8">
          <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300 ease-in-out">
            <h1 className="text-3xl font-semibold mb-4 text-center text-blue-900">
              RansomWare Prediction Tool
            </h1>
            <div className="flex items-center">
              <input
                type="text"
                placeholder="Email Address"
                value={emailAddress}
                onChange={handleEmailChange}
                className="py-2 px-4 m-5 rounded-full border border-gray-300 focus:outline-none focus:ring focus:ring-blue-300"
              />

              <label className="cursor-pointer bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full transition duration-300 ease-in-out">
                {selectedFileName || 'Choose File'}
                <input
                  type="file"
                  multiple
                  onChange={handlecsvFilesChange}
                  className="hidden"
                />
              </label>
              <button
                onClick={handleUpload}
                disabled={isUploading || !selectedFileName || !emailAddress}
                className={`ml-3 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full transition duration-300 ease-in-out ${isUploading ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
              >
                {isUploading ? 'Uploading...' : 'Upload'}
              </button>
            </div>
          </div>
        </div>
        {Object.keys(uploadedData).length > 0 && (
          <div className="mt-8">
            <h2 className="text-xl mb-4 text-blue-900">Uploaded Data One</h2>
            <div className="bg-blue-100 p-4 rounded-lg shadow-md">
              <pre className="whitespace-pre-wrap text-blue-800">
                {JSON.stringify(uploadedData, null, 2)}
              </pre>
            </div>
          </div>
        )}
        {/* Modal */}
        <Modal
          isOpen={showModal}
          contentLabel="Thank You Modal"
          onRequestClose={() => setShowModal(false)}
          className="custom-modal" // Add custom class
        >
          <h2>Thank you for using DeepRW!</h2>
          <p>You will receive the inference results on your email address: {emailAddress}</p>
          <button onClick={() => setShowModal(false)}>Close</button>
        </Modal>

      </div>
    </div>
  );
}

export default FileUploadPage;
